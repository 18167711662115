import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import tw from 'twin.macro';

import BackgroundImage from 'gatsby-background-image';
import articleIcon from './article.svg';
import videoIcon from './video.svg';
import imageStoryIcon from './image-story.svg'
import './ResourceCard.css';

const ResourceDiv = tw.div`
  rounded-md h-48 lg:h-40 lg:w-76 md:w-87 w-95 bg-center group-hover:shadow-md group-hover:-m-2 group-hover:h-52 lg:group-hover:h-44 lg:group-hover:w-80 md:group-hover:w-91 group-hover:w-99 transition
`;

const ResourceCategoryDiv = tw.div`
  rounded font-semibold space-x-1 uppercase bg-white p-1.5 h-6 flex flex-row justify-between items-center whitespace-normal
`;

const ResourceCardHeading = tw.h3`
  font-bold text-gray-700 text-base mt-3.5 leading-none whitespace-normal
`;

const ResourceCardMeta = tw.p`
  font-light text-xs text-gray-400 mt-1 leading-none whitespace-normal
`;

const getResourceIcon = (resourceType) => {
  resourceType = resourceType ?? 'General';

  switch (resourceType.toLowerCase()) {
    case 'image story':
      return imageStoryIcon;

    case 'video':
      return videoIcon;

    default:
      return articleIcon;
  }
}

const ResourceCard = ({name, topic, time_description, type, file, image_story_thumbnail, strapiId, ...props}) => {

  // Set ImageData.
  const imageData = file?.childImageSharp?.fluid;
  // Get thumbnails for image story resources
  const thumbnailData = image_story_thumbnail?.childImageSharp?.fluid;

  return (
    <Link to={`/resources/${strapiId}`} className="cursor-pointer mx-auto group" >
      <div {...props}>
      <ResourceDiv>
        <BackgroundImage
          Tag="div"
          className="resource-card rounded-md h-full w-full bg-center bg-cover"
          fluid={type?.toLowerCase() === 'image story' ? thumbnailData : imageData}
          backgroundColor={`#f3f4f6`}
          style={{
            // little hack to fix border radius on gatsby-background-image
            overflow: 'hidden',
          }}
          loading="lazy"
        >
          <div className="rounded-md p-2 w-full h-full flex flex-row justify-start items-start border-4 border-transparent group-hover:border-white group-hover:border-opacity-40">
          <ResourceCategoryDiv>
            <img src={getResourceIcon(type)} alt={type ?? 'General'}/>
            <p className="text-xs font-heading">{topic.Name}</p>
          </ResourceCategoryDiv>
          </div>
        </BackgroundImage>
      </ResourceDiv>

      <ResourceCardHeading className='resource-card-content'>
        {name}
      </ResourceCardHeading>
      <ResourceCardMeta className='resource-card-content'>
        {`${time_description ?? 'Unknown Length'} - ${type ?? 'General'}`}
      </ResourceCardMeta>
      </div>
    </Link>
  )
}

ResourceCard.propTypes = {
  name: PropTypes.node,
  topic: PropTypes.object,
  time_description: PropTypes.string,
  type: PropTypes.string,
  file: PropTypes.object,
  strapiId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
}

ResourceCard.defaultProps = {
  name: null,
  topic: null,
  time_description: null,
  type: null,
  file: null,
  strapiId: null,
}

export default ResourceCard;